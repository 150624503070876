<template>
  <div class="home">
    <b-row align-v="center" class="home_wrapper">
      <b-col lg="6">
        <b-row align-h="center">
          <b-col cols="10">
            <img :src="logo" width="300px"/>
            <p class="mb-4 mt-3 text-justify">
              Benvenuto in EnviApp, l’applicazione web per gestire in modo sistematico e digitale i principali
              adempimenti cogenti e volontari della tua organizzazione.
              Se hai già un account effettua il log in. In alternativa contattaci per ulteriori informazioni.
            </p>
            <b-button
                v-for="(app, index) in applications"
                variant="primary"
                class="mt-2 mr-2"
                :key="index"
                @click="goTo(app)"
            >
              Accedi in {{ app.name }}
            </b-button>
          </b-col>
        </b-row>

      </b-col>
      <b-col cols="6" class="bg_img"></b-col>
    </b-row>
  </div>
</template>

<script>
import logo from "@/assets/logo.png"

export default {
  name: 'Home',
  data() {
    return {
      logo: logo,
      protocol: 'https:',
      host: null,
      applications: [
        {"name": "HSE", "tag": "hse"},
        {"name": "Emissioni", "tag": "emissioni"},
        {"name": "F-GAS", "tag": "fgas"}
      ]
    }
  },
  methods: {
    goTo(item) {
      window.location.href = `${this.protocol}//${item.tag}.${this.host}`;
    }
  },
  beforeMount() {
    const {protocol, host} = window.location;
    this.protocol = protocol;
    this.host = host;
  }
}
</script>
<style lang="scss">
// App colors
$sga_color: #007bff;
$emissioni_color: #4caf50;
$fgas_color: #eea200;

.card {
  &.SGA {
    .card-text:before {
      content: '';
      width: 100%;
      height: 2px;
      display: block;
      margin: -6px auto 10px;
      background: $sga_color;
    }
  }

  &.Emissioni {
    .card-text:before {
      content: '';
      width: 100%;
      height: 2px;
      display: block;
      margin: -6px auto 10px;
      background: $emissioni_color;
    }
  }

  &.FGas {
    .card-text:before {
      content: '';
      width: 100%;
      height: 2px;
      display: block;
      margin: -6px auto 10px;
      background: $fgas_color;
    }
  }
}

.bg_img {
  background-image: url('./../assets/app.png');
  background-size: contain;
  background-repeat: no-repeat;
  height: 70vh;
}

@media (max-width: 960px) {
  .bg_img {
    display: none;
  }
  .home_wrapper {
    min-height: 60vh;
  }
}

</style>