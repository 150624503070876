<template>
  <div id="app">
    <div class="main-content" :class="{'collapsed': isActive}">
      <!----- NAV BAR ------------>
      <nt-vue-nav-bar
          :show-brand="true"
          :logo-src="logoSrc"
          logo-width="150px"
          homepage-link="/"
          :show-text-title="false"
          :showUserDropdown="false"
      >
        <template slot="beforeUser">
          <b-button variant="login"
                    :title="!isLogged ? 'Login': 'Logout'"
                    @click="$router.push(!isLogged ? 'login': 'logout')">
            <b-icon :icon="!isLogged ? 'box-arrow-in-right' : 'box-arrow-in-left' "></b-icon>
          </b-button>
        </template>
      </nt-vue-nav-bar>
      <!----- // NAV BAR ------------>
      <b-container fluid class="wrapper-home_card">
        <Home/>
      </b-container>
    </div>
    <!----- FOOTER ---------->
    <nt-vue-footer
        footer-credits="A.C.S"
        href="https://www.acs-polito.it/">
      <template v-slot:customContent>
        - Powered by
        <a data-v-bb8927de="" href="https://neratech.it/" target="_blank">NERAtech</a>
        <span> - {{ appVersion }}</span>
      </template>
    </nt-vue-footer>
    <!----- // FOOTER ---------->
  </div>
</template>

<script>
import ntVueNavBar from "@bit/freddy34.vue2x-components.nt-vue-nav-bar";
import ntVueFooter from "@bit/freddy34.vue2x-components.nt-vue-footer";
import Home from "@/views/Home";

export default {
  name: 'App',
  components: {
    Home,
    ntVueFooter,
    ntVueNavBar
  },
  data() {
    return {
      isActive: true,
      crumbs: [
        {
          text: 'Home',
          to: '/'
        },
      ],
      items: [
        {
          path: '/',
          name: 'Home',
          icon: 'house',
          title: 'Home',
          active: false,
          show: true,
        },
        {
          path: '/hse',
          name: 'hse',
          icon: 'clipboard-data',
          title: 'HSE',
          active: false,
          show: true,
        },
        {
          path: '/emissioni',
          name: 'emissioni',
          icon: 'clipboard-data',
          title: 'Emissioni',
          active: false,
          show: true,
        },
        {
          path: '/fgas',
          name: 'fgas',
          icon: 'journal-medical',
          title: 'FGas',
          active: false,
          show: true,
        }
      ]
    }
  },
  computed: {
    logoSrc() {
      return require('./assets/logo.png')
    },
    isLogged() {
      return false
    },
    appVersion(){
      return process.env.VUE_APP_VERSION;
    }
  },
  methods: {
    expandMain() {
      this.isActive = !this.isActive
    }
  }

}
</script>

<style>

.wrapper-home_card {
  padding-top: 4rem
}

@media only screen and (max-width: 1024px) {
  .wrapper-home_card {
    padding-top: 2rem
  }

  .card .nt-card-border {
    margin-bottom: 30px
  }
}

@media only screen and (max-width: 720px) {
  .wrapper-home_card {
    padding-top: 3rem
  }
}
</style>